.banner {
  background-image: url('~@/assets/img/about/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 1080px;
  text-align: center;
  padding: 180px 30px 0 30px;
  p {
    margin: 0;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32px;
    color: #000000;
    line-height: 44px;
    margin-bottom: 21px;
  }
  div {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 30px;
    letter-spacing: 1px;
  }
}

  
.wedesc {
  width: 100%;
  height: 400px;
  box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.12);
  padding: 80px 40px 0 40px;
  p {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32px;
    color: #000000;
    line-height: 44px;
    text-align: center;
    margin-bottom: 80px;
  }
  :global(.bottom) {
    display: flex;
    justify-content: space-between;
    :global(.item) {
      :global(.num) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: bold;
        font-size: 46px;
        color: #000000;
        line-height: 63px;
        text-align: center;
        font-style: normal;
        margin-bottom: 10px;

      }
      
      :global(.tip) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
      }
    }
    :global(.item.active) {
      :global(.num) {
        color: #FF8D32;
        text-shadow: 0px 2px 24px rgba(255,111,37,0.5);
      }
    }
  }
}

.concat_desc {
  background-color: #F1F3F6;
  padding: 80px 20px 80px 20px;
  text-align: center;
  p {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32px;
    color: #000000;
    line-height: 44px;
    margin-bottom: 60px;
  }
  :global(.bottom) {
    display: flex;
  }
  :global(.item) {
    display: inline-block;
    padding: 54px 40px 66px 40px;
    flex-basis: 33.3333%;
    transition: all 0.5s;
    img {
      margin-bottom: 12px;
    }
    :global(.icon){
      width: 44px;
      height: 32px;
      background: #ECEDEE;
      box-shadow: inset 0px 0px 8px 0px rgba(0,0,0,0.2);
      border-radius: 4px;
    }
    :global(.tip){
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      text-align: center;
    }
  }
  :global(.item.active) {
    background: radial-gradient( 84% 141% at 0% 0%, #FFF5F0 0%, #FFFEFA 47%, #FFFAFA 100%);
    border-radius: 12px;
  }
}

.branches {
  background-color: #FFFFFF;
  padding: 80px 80px 80px 80px;
  p {
    margin: 0;
    margin-bottom: 60px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32px;
    color: #000000;
    line-height: 44px;
    text-align: center;
    font-style: normal;
  }
  :global(.row){
    display: flex;
    flex-direction: column;

  }
  
  :global(.item){
    background: #FAFAFA;
    border: 1px solid #C3C3C3;
    border-radius: 12px;
    color: #000000;
    padding: 30px 40px 30px 40px;
    
    :global(.icon){
      font-weight: bold;
      font-size: 20px;
    }
    :global(.tip){
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: left;
      margin: 4px 0 25px 0;
    }
    :global(.tip2){
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 22px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      :global(span) {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
      }
      :global(.name){
        font-weight: bold;
        font-size: 16px;
        color: #000000;
        line-height: 22px;
        letter-spacing: 1px;
        text-align: left;
      }
      :global(.line){
        width: 2px;
        height: 16px;
        background: #CCCCCC;
        margin: 0 16px 0 16px;
      }
    }
  }
  :global(.item.active){
    background: radial-gradient( 158% 131% at 20% 0%, #FFBB5E 0%, #FF866B 74%, #FF8634 100%);
    box-shadow: 0px 4px 24px 0px rgba(239,104,34,0.5);
    border: none;
    border-radius: 12px;
    padding: 30px 40px 30px 40px;
    color: #FFFFFF;
    :global(.icon){
      font-weight: bold;
      font-size: 20px;
    }
    :global(.tip){
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: left;
      margin: 4px 0 25px 0;
    }
    :global(.tip2){
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      :global(span) {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
      }
      :global(.name){
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        letter-spacing: 1px;
        text-align: left;
      }
      :global(.line){
        width: 2px;
        height: 16px;
        background: #FFFFFF;
        margin: 0 16px 0 16px;
      }
    }
  }
}
