.swiper-slide {
    background-color: #F1F3F6;
}

.ant-collapse-content-box {
    padding: 0 !important;
}
.ant-collapse {
    border-radius: 0 !important;
}
.ant-collapse-header {
    padding: 38px !important;
}
.ant-collapse {
    border: none !important;
}
.sph6 {
    padding-bottom: 60px;
}

.sph5 {
    padding-bottom: 60px;
}