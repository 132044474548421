.wrap {
  background: #F1F3F6;
  .banner {
    background-image: url('~@/assets/img/banner_pc.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 240PX;
    padding-left: 240PX;
    box-sizing: border-box;
    .title1 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: bold;
      font-size: 36PX;
      color: #FFFFFF;
      line-height: 50PX;
      text-shadow: 0PX 2PX 4PX rgba(0,0,0,0.5);
      margin-bottom: 20PX;
    }
    .title2 {
      margin-bottom: 40PX;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 16PX;
      color: #FFFFFF;
      line-height: 22PX;
      letter-spacing: 1PX;
      text-shadow: 0PX 2PX 4PX rgba(0,0,0,0.5);
      width: 666px;
    }
    .orangebtn {
      display: inline-flex;
      box-sizing: border-box;
      padding: 15PX 20PX;
      background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
      box-shadow: 0PX 4PX 24PX 0PX rgba(0,0,0,0.2);
      border-radius: 8PX;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: bold;
      font-size: 18PX;
      color: #FFFFFF;
      letter-spacing: 1PX;
      text-align: center;
      margin-bottom: 214PX;
    }
  }
  .search1 {
    position: relative;
}
  .search {
    // width: 80%;
    // max-width: 1400PX;
    // height: 100PX;
    background: #FFFFFF;
    box-shadow: 0PX 2PX 24PX 0PX rgba(0,0,0,0.08);
    border-radius: 16PX;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 40PX;
    .item {
      cursor: pointer;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 24PX;
      color: #000000;
      margin-right: 40PX;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &._active {
        font-weight: bold;
        .sep{
          display: inline-block;
          width: 48PX;
          height: 4PX;
          background: #FF8F34;
          border-radius: 1PX;
          margin-top: 4PX;

        }
      }
    }
    .sep1 {
      width: 2PX;
        height: 60PX;
        background: #CBCBCB;

    }
    .bottom {
      width: calc(100% - 460PX);
        padding: 40PX;
        input {
          height: 60PX;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          font-size: 24PX;
          color: black;
          line-height: 33PX;
          caret-color:transparent;
          border-color: transparent;
          &::placeholder {
            font-weight: 400;
            font-size: 24PX;
            color: #999999;
            line-height: 33PX;
            caret-color:transparent;
            border-color: transparent;
          }
          &:focus {
            border-color: #f5985a;
            box-shadow: 0 0 0 2PX rgba(231, 174, 108, 0.1);
          }
        } 
    }
    :global(.freight) {
      display: flex;
      padding: 20px 30px 20px 30px;

      :global(.ant-select) {
        width: 160px;
        height: 40px;
        margin-right: 20px;
        display: block;
      }
      :global(.ant-picker){
        width: 160px;
        height: 40px;
        display: block;
        margin-right: 30px;
      }
      :global(img) {
        width: 40px;
        height: 40px;
      }
    }
    :global(.rates) {
      width: 830px;
      box-sizing: border-box;
    }
    :global(.quote) {
      display: flex;
      padding: 20px 30px 20px 30px;
      :global(input) {
        width: 320px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        margin-right: 20px;
      }
      :global(.btn_now) {
        display: inline-block;
        cursor: pointer;
        padding: 9px 15px;
        background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
        box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.2);
        border-radius: 4px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        white-space: nowrap;
        height: auto;
      }
      :global(.btn_now:hover) {
        border: none;
      }
      
    }
    :global(.ant-select-focused), 
    :global(.ant-select:hover), 
    :global(.ant-picker-focused),
    :global(.ant-picker:hover)
     {
      :global(.ant-select-selector) {
        border-color: #FFBB5E !important;
        box-shadow: none !important;
      }

    }
    :global(.ant-picker-focused),
    :global(.ant-picker:hover)
     {
       border-color: #FFBB5E !important;
       box-shadow: none !important;
    }
  }
  .service {
    padding: 110PX 0 100PX 0;
    h1 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: bold;
      font-size: 32PX;
      color: #000000;
      line-height: 44px;
      text-align: center;
      margin-bottom: 12PX;
    }
    h4 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 16PX;
      color: #666666;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: center;
      margin-bottom: 46PX;
    }
    .serviceArr {
      display: flex;
      justify-content: center;
      .item {
        width: 200PX;
        height: 360PX;
        border-radius: 16PX;
        padding-top: 236PX;
        padding-left: 30PX;
        padding-right: 30PX;
        box-sizing: border-box;
        transition: width 0.5s;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        &.targetService {
          width: 560PX;
        }
        h2 {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: bold;
          font-size: 28PX;
          color: #FFFFFF;
          line-height: 38PX;
          text-align: left;
          margin: 0;
          margin-bottom: 12PX;
        }
        h3 {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          font-size: 16PX;
          color: #FFFFFF;
          line-height: 22PX;
          letter-spacing: 1PX;
          text-align: left;
          margin: 0;
        }
        div {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: bold;
          font-size: 20PX;
          color: #FFFFFF;
          line-height: 38PX;
        }
      }
      .item + .item {
        margin-left: 20PX;
      }
      
    }
    
  }

  .ai {
    background-color: white;
    .aitop {
      box-shadow: 0PX 2PX 24PX 0PX rgba(0,0,0,0.12);
      margin-bottom: 60PX; 
      padding-top: 60PX;
    }
    h1 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: bold;
      font-size: 32PX;
      color: #000000;
      line-height: 44px;
      text-align: center;
      margin-bottom: 12PX;
    }
    h4 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 16PX;
      color: #666666;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: center;
      margin-bottom: 46PX;
    }

    .aiMethods {
      display: flex;
      justify-content: center;
      .img {
        width: 63PX;
      }
      .aititle {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 18PX;
        color: #000000;
        line-height: 25PX;
        letter-spacing: 1PX;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 24PX;
        &.active_aititle {
          border-bottom: 4PX solid #FF866B;
        }
        div:nth-child(2){
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 500;
          font-size: 18PX;
          color: #000000;
          line-height: 25PX;
          letter-spacing: 1PX;
          text-align: center;
          margin: 16PX 0 40PX 0;
        }
      }
    }
    .slideItem {
      width: 100%;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      .left {
        box-sizing: border-box;
        width: 300PX;
        height: 200PX;
        border: 1PX solid #000000;
        margin-right: 40PX;
      }
      .right {
        display: flex;
        justify-content: space-between;
        width: 80%;
        .leftC {
          text-align: left;
          max-width: 60%;
          div {
            &:nth-child(1) {
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
              font-weight: bold;
              font-size: 28PX;
              color: black;
              line-height: 38PX;
              text-align: left;
            }
            &:nth-child(2) {
              width: 520PX;
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
              font-weight: 400;
              font-size: 16PX;
              color: #666666;
              line-height: 22PX;
              letter-spacing: 1PX;
              text-align: left;
              margin: 16PX 0 38PX 0;
            }
          }
          
          ul {
            list-style: none;
            text-align: left;
            padding: 0;
            margin: 0;
            li {
              span {
                &:nth-child(1) {
                  display: inline-block ;
                  width: 8PX;
                  height: 8PX;
                  background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
                  box-shadow: 0PX 4PX 24PX 0PX rgba(0,0,0,0.2);
                  border-radius: 50%;
                  margin-right: 7PX;
                }
                &:nth-child(2){
                  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                  font-weight: 400;
                  font-size: 16PX;
                  color: #000000;
                  letter-spacing: 1PX;
                  text-align: left;
                }
              }
              
              
            }
          }

        }
        .rightC {
          display: flex;
          align-items: center;
          div{
            &:first-child {
              width: 160PX;
              height: 56PX;
              background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
              box-shadow: 0PX 4PX 24PX 0PX rgba(239,104,34,0.5);
              border-radius: 8PX;
  
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
              font-weight: bold;
              font-size: 18PX;
              color: #FFFFFF;
              line-height: 3;
              letter-spacing: 1PX;
              text-align: center;
              margin-right: 20PX;
            }
            &:nth-child(2) {
              width: 160PX;
              height: 56PX;
              background: #FFFFFF;
              border-radius: 8PX;
              border: 1PX solid #CCCCCC;
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
              font-weight: bold;
              font-size: 18PX;
              color: #333333;
              line-height: 3;
              letter-spacing: 1PX;
              text-align: center;
            }
          }
          
        }
      }
    }
    :global(.swiper-slide) {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      background-color: #fff;
  }
  }

  .friends {
    padding-bottom: 80PX;
    padding-top: 40PX;
    h1 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: bold;
      font-size: 32PX;
      color: #000000;
      line-height: 44px;
      text-align: center;
      margin-bottom: 12PX;
    }
    h4 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 16PX;
      color: #666666;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: center;
      margin-bottom: 46PX;
    }
    .friendslogoimgs {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .logoarr + .logoarr {
      margin-top: 20PX;
    }
    .logoarr {
      display: flex;
      flex-wrap: wrap;
      .friendsLogo {
        width: 272px;
        height: 100px;
        max-width: 272PX;
        max-height: 100PX;
        background: #FFFFFF;
        box-shadow: 0PX 4PX 24PX 0PX rgba(0,0,0,0.08);
        border-radius: 8PX;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
        }
      }
      .friendsLogo + .friendsLogo {
        margin-left: 20px;
      }
    }
  }

  .tradedata {
    background-image: url('~@/assets/img/foot_banner.png');
    padding-top: 80px;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    h1 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: bold;
      font-size: 32PX;
      color: #FFFFFF;
      line-height: 44PX;
      text-align: center;
      margin-bottom: 12PX;
    }
    h4 {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 500;
      font-size: 16PX;
      color: #FFFFFF;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: center;
      margin-bottom: 40PX;
    }
    .item {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: bold;
      font-size: 46PX;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      > span {
        display: block;
        margin-right: 20px;
        span:nth-child(1){
          padding: 0 12PX 5PX 12PX;
        }
      }
      
      .fob_active {
        span:nth-child(2){
          display: block;
          width: 100%;
          height: 4PX;
          background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
          box-shadow: 0PX 4PX 24PX 0PX rgba(0,0,0,0.2);
        } 
      }
    }
  }

  
}


