.inquiryPage1 {
    background-color: #F1F3F6;
    text-align: center;
    :global(.outpart) {
        overflow-y: scroll;
        height: 324px;
    }
    
    :global(.page) {
        display: inline-block;
        text-align: left;
        background-color: white;
        margin-top: 50PX;
        margin-bottom: 100PX;
        :global(.h1) {
            font-weight: bold;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
            background-color: white;
            margin-left: 30px;
            margin-top: 30px;
        }
        :global(.h3) {
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            margin: 16px 0;
        }

        :global(.content) {
            padding: 30px;
            background-color: white;
            display: flex;
            :global(.leftc) {
                margin-right: 100px;
                :global(.boxs){
                    display: flex;
                    a {
                        text-decoration: none;
                    }
                }
                
                :global(.boxs .item) {
                    font-weight: 400;
                    font-size: 14px;
                    color: #666666;
                    line-height: 20px;
                    padding: 7px 25px;
                    background: #F1F3F6;
                    border-radius: 3px;
                    margin-right: 10px;
                }
                :global(.boxs .item.item-active) {
                    font-weight: 400;
                    font-size: 14px;
                    color: white;
                    line-height: 20px;
                    padding: 7px 25px;
                    background: #FF954D;
                    border-radius: 3px;
                }

                :global(.gp20) {
                    display: flex;
                    margin: 34px 0 42px 0;
                    :global(.ant-input-number-group-addon) {
                        padding: 0;
                        :global(div) {
                            cursor: pointer;
                            width: 30px;
                            height: 20px;
                        }
                    }
                    :global(.ant-input-number-handler-wrap){
                        display: none;
                    }
                    :global(.gp-left) {
                        margin-right: 40px;

                    }
                    
                    :global(.item) {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        :global(.n1) {
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                            line-height: 22px;
                            width: 68px;
                            margin-right: 12px;
                        }
                        :global(.ant-input-number-group-wrapper) {
                            width: 160px;
                            height: 34px;
                        }
                        

                    }
                }
            }
            :global(.rightc) {
                :global(.ant-tabs) {
                    max-width: 520px;
                    :global(.panelc){
                        padding: 20px 0 0 0;
                    }
                    :global(.panelc .row) {
                        display: flex;
                        margin-bottom: 20px;
                    }
                    :global(.panelc .row div:nth-child(1)) {
                        margin-right: 40px;
                    }
                    :global(.panelc .row .tip) {
                        font-weight: 400;
                        font-size: 12px;
                        color: #666666;
                        line-height: 17px;
                        margin-bottom: 3PX;
                    }
                    :global(.panelc .row input) {
                        width: 240px;
                        height: 34px;
                    }
                }
                :global(.box1) {
                    display: flex;
                    padding: 12px 20px;
                    border-radius: 3px;
                    border: 1px solid #CCCCCC;
                    margin: 20px 0 30px 0;
                    justify-content: space-between;
                    :global(.boxl){
                        display: flex;
                        :global(.item){
                            flex-shrink: 0;
                        }
                        :global(.item .name) {
                            font-weight: 400;
                            font-size: 12px;
                            color: #666666;
                            line-height: 17px;
                            margin-bottom: 8px;
                        }
                    }
                    
                    :global(.boxr button) {
                        // width: 130px;
                        // height: 34px;
                        border: 1px solid #FF954D;
                        color: #FF954D;
                        margin-left: 10px;
                        padding: 9px 16px;
                        line-height: 100%;
                    }
                    :global(.item .value) {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        line-height: 20px;

                    }
                    :global(.line) {
                        width: 1PX;
                        height: 50px;
                        background-color: #CCCCCC;
                        margin: 0 16px;
                    }
                }
                :global(.box1.box2 .value) {
                    font-weight: bold;
                    font-size: 18px;
                    color: #000000;
                    line-height: 25px;
                }
                
                
            }
            :global(a.ora) {
                font-weight: 400;
                font-size: 12px;
                color: #FF954D;
                line-height: 17px;
            }
        }

        :global(.bottom) {
            display: flex;
            background: #F9FAFB;
            border-radius: 0px 0px 12px 12px;
            padding: 15px 30px;
            justify-content: space-between;
            align-items: center;
            :global(.left) {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
            }
            :global(.right) {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                display: flex;
                align-items: center;
                :global(.leftod) {
                    :global(.bold) {
                        font-weight: bold;
                        font-size: 24px;
                        color: #000000;
                        line-height: 33px;
                    }

                    :global(.light) {
                        font-weight: 400;
                        font-size: 12px;
                        color: #666666;
                        line-height: 17px;
                    }
                }
                :global(.btn1) {
                    color: #FF954D;
                    border: 1px solid #FF954D;
                    margin: 0 12px 0 20px;
                    padding: 10px 46px;
                    line-height: 100%;
                }
                :global(.btn2) {
                    padding: 10px 56px;
                    line-height: 100%;

                }
            }

        }
    }
}

.inquiryPage {
    background-color: #F1F3F6;
    text-align: center;
    :global(.outpart) {
        overflow-y: scroll;
        height: 324px;
    }
    :global(.page) {
        display: inline-block;
        text-align: left;
        background-color: white;
        margin-top: 50PX;
        margin-bottom: 100PX;
        :global(.h1) {
            font-weight: bold;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
            background-color: white;
            margin-left: 30px;
            margin-top: 30px;
        }
        :global(.h3) {
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            margin: 16px 0;
        }

        :global(.content) {
            padding: 30px;
            background-color: white;
            display: flex;
            :global(.leftc) {
                margin-right: 100px;
                :global(.ant-collapse-header) {
                    padding: 0 !important;
                }
                :global(.add-item) {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 400;
                    font-size: 14PX;
                    color: #666666;
                    line-height: 20px;
                    width: 70%;
                }
                :global(.boxs){
                    display: flex;
                    a {
                        text-decoration: none;
                    }
                }
                
                :global(.boxs .item) {
                    font-weight: 400;
                    font-size: 14px;
                    color: #666666;
                    line-height: 20px;
                    padding: 7px 25px;
                    background: #F1F3F6;
                    border-radius: 3px;
                    margin-right: 10px;
                }
                :global(.boxs .item.item-active) {
                    font-weight: 400;
                    font-size: 14px;
                    color: white;
                    line-height: 20px;
                    padding: 7px 25px;
                    background: #FF954D;
                    border-radius: 3px;
                }
                :global(.row-1) {
                    background: #FFFFFF;
                    border-radius: 3px;
                    border: 1px solid #DDDDDD;
                    padding: 22px 45px;
                    margin: 30px 0;
                    display: flex;
                    align-items: center;
                    :global(.title) {
                        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                        font-weight: bold;
                        font-size: 18px;
                        color: #000000;
                        line-height: 25px;
                    }
                    :global(.arrow) {
                        width: 100px;
                        height: 10px;
                        margin: 0 20px;
                        flex-shrink: 0;
                        // background: linear-gradient( 158deg, rgba(255,116,13,0) 0%, #FF8100 100%);
                    }
                }

                

                :global(.box1) {
                    display: flex;
                    padding: 12px 20px;
                    border-radius: 3px;
                    border: 1px solid #CCCCCC;
                    margin: 20px 0 30px 0;
                    justify-content: space-between;
                    :global(.boxl){
                        display: flex;
                        :global(.item){
                            flex-shrink: 0;
                        }
                        :global(.item .name) {
                            font-weight: 400;
                            font-size: 12px;
                            color: #666666;
                            line-height: 17px;
                            margin-bottom: 8px;
                        }
                    }
                    
                    :global(.boxr button) {
                        // width: 130px;
                        // height: 34px;
                        border: 1px solid #FF954D;
                        color: #FF954D;
                        margin-left: 10px;
                        padding: 9px 16px;
                        line-height: 100%;
                    }
                    :global(.item .value) {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        line-height: 20px;

                    }
                    :global(.line) {
                        width: 1PX;
                        height: 50px;
                        background-color: #CCCCCC;
                        margin: 0 16px;
                    }
                }
            }
            :global(.rightc) {
                :global(.ant-tabs) {
                    max-width: 520px;
                    :global(.panelc){
                        padding: 20px 0 0 0;
                    }
                    :global(.panelc .row) {
                        display: flex;
                        margin-bottom: 20px;
                    }
                    :global(.panelc .row div:nth-child(1)) {
                        margin-right: 40px;
                    }
                    :global(.panelc .row .tip) {
                        font-weight: 400;
                        font-size: 12px;
                        color: #666666;
                        line-height: 17px;
                        margin-bottom: 3PX;
                    }
                    :global(.panelc .row input) {
                        width: 240px;
                        height: 34px;
                    }
                }
                :global(.gp20) {
                    display: flex;
                    margin: 34px 0 42px 0;
                    :global(.ant-input-number-group-addon) {
                        padding: 0;
                        :global(div) {
                            cursor: pointer;
                            width: 30px;
                            height: 20px;
                        }
                    }
                    :global(.ant-input-number-handler-wrap){
                        display: none;
                    }
                    :global(.gp-left) {
                        margin-right: 40px;

                    }
                    
                    :global(.item) {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        :global(.n1) {
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                            line-height: 22px;
                            width: 68px;
                            margin-right: 12px;
                        }
                        :global(.ant-input-number-group-wrapper) {
                            width: 160px;
                            height: 34px;
                        }
                        

                    }
                }
                :global(.box1) {
                    display: flex;
                    padding: 12px 20px;
                    border-radius: 3px;
                    border: 1px solid #CCCCCC;
                    margin: 20px 0 30px 0;
                    justify-content: space-between;
                    :global(.boxl){
                        display: flex;
                        :global(.item){
                            flex-shrink: 0;
                        }
                        :global(.item .name) {
                            font-weight: 400;
                            font-size: 12px;
                            color: #666666;
                            line-height: 17px;
                            margin-bottom: 8px;
                        }
                    }
                    
                    :global(.boxr button) {
                        // width: 130px;
                        // height: 34px;
                        border: 1px solid #FF954D;
                        color: #FF954D;
                        margin-left: 10px;
                        padding: 9px 16px;
                        line-height: 100%;
                    }
                    :global(.item .value) {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        line-height: 20px;

                    }
                    :global(.line) {
                        width: 1PX;
                        height: 50px;
                        background-color: #CCCCCC;
                        margin: 0 16px;
                    }
                }
                :global(.box1.box2 .value) {
                    font-weight: bold;
                    font-size: 18px;
                    color: #000000;
                    line-height: 25px;
                }
                
                
            }
            :global(a.ora) {
                font-weight: 400;
                font-size: 12px;
                color: #FF954D;
                line-height: 17px;
            }
        }

        :global(.bottom) {
            display: flex;
            background: #F9FAFB;
            border-radius: 0px 0px 12px 12px;
            padding: 15px 30px;
            justify-content: space-between;
            align-items: center;
            :global(.left) {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
            }
            :global(.right) {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                display: flex;
                align-items: center;
                :global(.leftod) {
                    :global(.bold) {
                        font-weight: bold;
                        font-size: 24px;
                        color: #000000;
                        line-height: 33px;
                    }

                    :global(.light) {
                        font-weight: 400;
                        font-size: 12px;
                        color: #666666;
                        line-height: 17px;
                    }
                }
                :global(.btn1) {
                    color: #FF954D;
                    border: 1px solid #FF954D;
                    margin: 0 12px 0 20px;
                    padding: 10px 46px;
                    line-height: 100%;
                }
                :global(.btn2) {
                    padding: 10px 56px;
                    line-height: 100%;

                }
            }

        }
    }
}