footer {
  padding: 60px 40px 50px 40px;
  background-color: #F1F3F6;
  .logo {
      width: 180px;
      margin-bottom: 40px;
  }
  .menus {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      line-height: 33px;
      letter-spacing: 1px;
      text-align: center;
      display: flex;
      .item {
          display: flex;
          align-items: center;
      }
      .line {
          width: 2px;
          height: 16px;
          background-color: #CDCDCD;
          margin-left: 42px;
          margin-right: 30px;
      }
  }
  .bottom {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 24px;
      color: #666666;
      line-height: 33px;
      letter-spacing: 1px;
      text-align: left;
      display: flex;
      margin-top: 30px;
      justify-content: space-between;
      .left {
          display: flex;
          img {
              width: 30px;
              height: 30px;
              margin-right: 8px;
          }
          div {
              width: 520px;
          }
      }
      >img {
          width: 80px;
      }
  }
}