.quote_modal {
    width: 1080px !important;
    box-sizing: border-box;
    :global(.ant-modal-title) {
        font-weight: bold;
        font-size: 18PX;
    }
    :global(.ant-modal-content) {
        padding: 20px 30px 30px 30px;

    }
}
.form_basic {
    :global(.block) {
        :global(.tip) {
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            margin: 10px 0 20px 0;
        }
        :global(.item1) {
            margin-right: 20px;
        }
        :global(label) {
            font-size: 12PX;
        }
        
    }
    :global(.content) {
        display: flex;
        :global(.left) {
            width: 600px;
            display: flex;
            // flex-wrap: wrap;
            // margin-right: 20px;
        }
    }
    :global(.ant-select) {
        width: 240px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 3px;
        // &:nth-child(2n) {
        //     margin-left: 20px;
        // }
    }
    :global(.ant-picker) {
        width: 240px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #CCCCCC;
        // margin-left: 20px;

    }
    :global(input) {
        width: 500px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #CCCCCC;
    }
    :global(textarea) {
        width: 500px;
        height: 115px;
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #CCCCCC;
    }
    :global(.note) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 12PX;
        color: #999999;
        line-height: 17px;
        text-align: center;
        margin-top: 30px;
        :global(.hightlight) {
            color: #FD9C5A;
        }
    }
    :global(.Submit_btn) {
        display: inline-block;
        cursor: pointer;
        padding: 13px 203px;
        background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
        box-shadow: 0px 2px 16px 0px rgba(0,0,0,0.2);
        border-radius: 6px;

        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
        border: none;
        height: auto;
    }
    :global(.Submit_btn:hover){
        color: #FFFFFF !important;
        background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%) !important;
    }
    :global(.down) {
        text-align: center;
    }
    :global(input[type="checkbox"]:checked) {
        background-color: #FF954D;
    }
    :global(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: #FF954D;
        border-color: #FF954D;
    }
    :global(input[type="checkbox"]:hover) {
        background-color: #fff !important;
        border-color: #FF954D !important; 
    }
    :global(.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner){
        // background-color: #FF954D !important;
        border-color: #FF954D !important; 
    }
    :global(.ant-checkbox-checked:hover .ant-checkbox-inner){
        background-color: #FF954D !important;
        border-color: #FF954D !important; 
    }
    :global(label:hover .ant-checkbox-inner){
        border-color: #FF954D !important; 

    }
    :global(.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner) {
        background-color: #FF954D !important;
        border-color: #FF954D !important; 
        color: white !important;
    }
}