.header {
  height: 0;
  width: 100%;
  box-sizing: border-box;
  header {
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      padding: 10px 20px 10px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      z-index: 9999;
      background: linear-gradient( 180deg, #000000 0%, rgba(0,0,0,0.5) 100%);
  }
  
  .logo {
      width: 186px;
  }
  .menu {
      width: 60px;
  }
}
.drop {
  width: 260px;
  height: 402px;
  background: #FFFFFF;
  box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.08);
  border-radius: 8px;


  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 1px;
  text-align: left;
  padding: 50px 50px 0 50px;
  box-sizing: border-box;
  position: fixed;
  top: 60px;
  right: 30px;
  z-index: 99999;
  .item {
      margin-bottom: 50px;
      text-decoration: none;
      color: #000000;
      display: block;
      &:hover {
        color: #FF8D32;
      }
  }
  ._active {
      font-weight: 500;
      color: #FF8D32;
  }
}