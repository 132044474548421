.detailcostmodal {
    width: 830px !important;
    :global(.ant-modal-content) {
        padding: 0;

    }
    :global(.ant-modal-title) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
        padding: 30px;
        padding-bottom: 0px;
        
    }
    
}
.detailCost {
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    border-top: none;
    :global(.top) {
        padding: 30px;
        padding-top: 0px;
    }
    :global(.title) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
    }
    :global(.row) {
        background: #FFFFFF;
        border-radius: 3px;
        border: 1px solid #DDDDDD;
        padding: 22px 45px;
        margin: 30px 0;
        display: flex;
        align-items: center;
        :global(.title) {
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
        }
        :global(.arrow) {
            width: 100px;
            height: 10px;
            margin: 0 20px;
            flex-shrink: 0;
            // background: linear-gradient( 158deg, rgba(255,116,13,0) 0%, #FF8100 100%);
        }
    }
    :global(.titlep) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        margin: 20px 0;
    }
    :global(.pro) {
        display: flex;
        flex-flow: row wrap;
        width: 768px;
        border-left: 1px solid #DDDDDD;
        border-top: 1px solid #DDDDDD;
        :global(.cell) {
            display: flex;
            flex-wrap: nowrap;
            :global(.t1) {
                width: 193px;
                height: 48px;
                background: #FFFFFF;
                border-bottom: 1px solid #DDDDDD;
                border-right: 1px solid #DDDDDD;

                font-weight: 400;
                font-size: 12px;
                color: #666666;
                line-height: 48px;
                text-align: center;
            }
            :global(.t2) {
                width: 190px;
                height: 48px;
                background: #FFFFFF;
                border-bottom: 1px solid #DDDDDD;
                border-right: 1px solid #DDDDDD;
                font-weight: 500;
                font-size: 12px;
                color: #000000;
                line-height: 48px;
                text-align: center;

            }
        }

    }

    :global(.down) {
        display: flex;
        justify-content: flex-end;
        background: #F9FAFB;
        border-radius: 0px 0px 12px 12px;
        padding: 13px 30px 13px 0;
        :global(.p1,.p2) {
            :global(.a1) {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
            }
        }
        :global(.p1) {
            margin-right: 40px;
            :global(.a2) {
                font-weight: bold;
                font-size: 24px;
                color: #666666;
                line-height: 33px;
            }
        }
        :global(.p2) {
            :global(.a2) {
                font-weight: bold;
                font-size: 24px;
                color: #FF954D;;
                line-height: 33px;
            }
        }
    }
    
}