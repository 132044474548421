.detailcostmodal {
    width: 580px !important;
    :global(.ant-modal-content) {
        padding: 0;

    }
    :global(.ant-modal-title) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
        padding: 30px;
        padding-bottom: 0px;
        
    }
    
}
.detailCost {
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    border-top: none;
    :global(.top) {
        padding: 30px;
        padding-top: 0px;
        :global(.ant-collapse-header) {
            padding: 0 !important;

        }
        :global(.ant-collapse-item){
            border-radius: 3px;
            border: 1px solid #CCCCCC;
            margin-bottom: 20px;
            padding: 20px;
        }
    }
    :global(.title) {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
        line-height: 25px;
    }
    :global(.row) {
        background: #FFFFFF;
        border-radius: 3px;
        // border: 1px solid #DDDDDD;
        // padding: 22px 45px;
        margin: 12px 0;
        display: flex;
        align-items: center;
        :global(.title) {
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: bold;
            font-size: 14px;
            color: #000000;
            line-height: 25px;
        }
        :global(.arrow) {
            width: 30px;
            height: 6px;
            margin: 0 10px;
            flex-shrink: 0;
            // background: linear-gradient( 158deg, rgba(255,116,13,0) 0%, #FF8100 100%);
        }
    }
    :global(.down) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        :global(.dl) {
            display: flex;
            :global(.title) {
                display: flex;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                line-height: 17px;
            }
            :global(.td) {
                font-weight: bold;
                font-size: 24px;
                color: #000000;
                line-height: 33px;
                :global(.day) {
                    font-weight: 400;
                    font-size: 12px;
                    color: #000000;
                    line-height: 17px;
                }
            }
        }
        :global(.dl .d1) {
            margin-right: 40px;
        }
        :global(.dr button) {
            padding: 9px 16px;
            line-height: 100%;
            height: auto;
            width: auto;
            
        }
        :global(.dr .b2) {
            margin-left: 12px;
            flex-shrink: 0;
            border: 1px solid #FF954D;
            color: #FF954D;
            padding: 9px 28px;

        }
        
    }
    :global(.outpart) {
        padding: 0;
        border: none;
        margin-top: 30px;
    }
    
}