body, html, #root{
    margin: 0;
    width: 100%;
    height: 100%;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul,li{
    list-style: none;
}
