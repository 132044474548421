.wrap {
    // position: relative;
}

.banner {
    position: relative;
    background-image: url('~@/assets/img/banner_h5.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 160px 0 240px 40px;
    .text {
        z-index: 22;
        p {
            margin: 0;
        }
        p:nth-child(1) {
            width: 527px;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: bold;
            font-size: 36px;
            color: #FFFFFF;
            line-height: 50px;
            text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
        }
        p:nth-child(2) {
            width: 530px;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 33px;
            letter-spacing: 1px;
            text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
            margin: 20px 0 40px 0;
        }
        .btn {
            padding: 20px 24px;
            background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
            box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.2);
            border-radius: 12px;
            display: inline-flex;
            align-items: center;
            div {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                letter-spacing: 1px;
                margin-right: 12px;
            }
            img {
                width: 28px;
            }
        }
    }
}

.search1 {
    position: relative;
}
.search {
    background: #FFFFFF;
    box-shadow: 0px 2px 24px 0px rgba(0,0,0,0.08);
    border-radius: 16px;
    padding: 20px 40px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 670px;
    .top {
        display: flex;
        padding-bottom: 10px;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 40px;
            .text {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: 400;
                font-size: 24px;
                color: #000000;
                line-height: 33px;
            }
            .line {
                width: 48px;
                height: 4px;
                background: #FFFFFF;
                border-radius: 1px;
                margin-top: 7px;
            }
        }
        .item._active .text {
            font-weight: bold;
        }
        .item._active .line {
            background: #FF8F34;
        }
    }
    .ant-input-affix-wrapper {
        width: 76%;
    }
    input {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 24px;
        color: #999999;
        caret-color:transparent;
        border-color: transparent;
        padding-left: 0;
          &::placeholder {
            font-weight: 400;
            font-size: 24px;
            color: #999999;
            line-height: 33px;
            caret-color:transparent;
            border-color: transparent;
          }
          &:focus {
            border-color: #f5985a;
            box-shadow: 0 0 0 2px rgba(231, 174, 108, 0.1);
          }
    }
    img {
        width: 40px;
        height: 40px;
    }
}

.service {
    background-color: #F1F3F6;
    .title {
        padding: 188px 40px 60px 40px;
        p {
            margin: 0;
            &:nth-child(1) {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: bold;
                font-size: 32px;
                color: #000000;
                line-height: 44px;
                text-align: center;
                margin-bottom: 12px;
            }
            &:nth-child(2) {
                font-weight: 400;
                font-size: 24px;
                color: #666666;
                line-height: 33px;
                letter-spacing: 1px;
                text-align: center;
            }
        }
    }
    .slideItem {
        width: 560px;
        height: 360px;
        box-sizing: border-box;
        padding: 180px 30px 30px 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #FFFFFF;
        border-radius: 16px;
        p {
            width: 500px;
            margin: 0;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            color: #FFFFFF;
            text-align: left;
            &:nth-child(1) {
                font-weight: bold;
                font-size: 28px;
                line-height: 38px;
                margin-bottom: 12px;
            }
            &:nth-child(2) {
                font-weight: 400;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 33px;
                letter-spacing: 1px;
            }
        }
    }
    :global(.swiper-slide) {
        display: flex;
        justify-content: center;
    }
}

.ai {
    .text {
        padding: 80px 40px 60px 40px;
        background-color: #F1F3F6;
        p {
            margin: 0;
            &:nth-child(1) {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: bold;
                font-size: 32px;
                color: #000000;
                line-height: 44px;
                text-align: center;
            }
            &:nth-child(2) {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: 400;
                font-size: 24px;
                color: #666666;
                line-height: 33px;
                letter-spacing: 1px;
                text-align: center;
            }
        }
    }
    .top {
        display: flex;
        width: 520px;
        img {
            width: 63px;
            height: 72px;
            margin-right: 27px;
        }
        p {
            margin: 0;
        }
        p:nth-child(1) {
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: bold;
            font-size: 28px;
            color: #000000;
            line-height: 38px;
            text-align: left;
            margin-bottom: 8px;
        }
        p:nth-child(2) {
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            font-size: 24px;
            color: #666666;
            line-height: 33px;
            letter-spacing: 1px;
            text-align: left;
        }
    }
    :global(.ant-collapse-content) {
        border-top: 0;
    }

    .ul {
        background-color: #F1F3F6;
        padding: 30px;
        .li {
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;
            .dot {
                width: 12px;
                height: 12px;
                background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
                box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.2);
                border-radius: 50%;
                margin-right: 10px;
                box-sizing: border-box;
                flex-shrink: 0;
                transform: translate(0px, 4PX);
            }
            .text1 {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: 400;
                font-size: 24px;
                color: #000000;
                line-height: 33px;
                letter-spacing: 1px;
                text-align: left;
            }
            
        }
    }
}

.parterPart {
    padding: 80px 40px 60px 40px;
    background-color: #F1F3F6;
    .title {
        p {
            margin: 0;
            &:nth-child(1) {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: bold;
                font-size: 32px;
                color: #000000;
                line-height: 44px;
                text-align: center;
            }
            &:nth-child(2){
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: 400;
                font-size: 24px;
                color: #666666;
                line-height: 33px;
                letter-spacing: 1px;
                text-align: center;
                margin-bottom: 60px;
                margin-top: 12px;
            }
        }
    }
    .slideItem {
        .imgrow + .imgrow{
            margin-top: 21px;
        }
        .imgrow {
            display: flex;
            >div {
                padding: 10px 30px;
                background-color: white;
            }
            >div:nth-child(2) {
                margin-left: 22px;
            }
        }
        img {
            width: 262px;
            height: 96px;
        }
    }
}

.tradePart {
    background-image: url('~@/assets/img/foot_banner_h5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 40px 40px 40px;
    .top {
        p {
            margin: 0;
            &:nth-child(1) {
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: bold;
                font-size: 32px;
                color: #FFFFFF;
                line-height: 44px;
                text-align: center;
            }
            &:nth-child(2){
                font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                font-weight: 400;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 33px;
                letter-spacing: 1px;
                text-align: center;
                margin: 12px 0 40px 0;
            }
        }
    }
    .menus {
        display: flex;
        
        .item {
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: bold;
            font-size: 46px;
            color: #FFFFFF;
            line-height: 63px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .line {
                width: 70%;
                height: 4px;
                background: transparent;
            }
            &._active {
                .line {
                    background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
                    box-shadow: 0px 4px 24px 0px rgba(0,0,0,0.2);
                }
            }
        }
        .item + .item {
            margin-left: 12px;
        }
    }
}

