.search-block {

}
.form_ship {
  background-color: white;
  margin-top: 120px;
  justify-content: center;
  :global(.ant-select-selector) {
    box-shadow: none !important;
  }
  :global(.content-port) {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    position: relative;
    display: inline-block;
   
    :global(.ant-select), :global(.ant-picker) {
      height: 58px;
    }
    :global(.ant-picker) {
      width: 100%;
    }
    
  }
  :global(.content-port-2)  {
    margin: 0 12px;
  }
  :global(.ant-select-selector), :global(.ant-picker) {
    border: none !important;
    box-shadow: none !important;
  }
  :global(.ant-select-arrow) {
    display: none !important;
  }
  :global(.block_search .title) {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 12PX;
    color: #999999;
    position: absolute;
    z-index: 11;
    transform: translate(15px, -15px);
    background-color: white;
    padding: 5px 10px;
  }
  :global(.block_search .title.title_end) {
    transform: translate(380px, -15px);
  }
  :global(.title_down) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    :global(.arrow) {
      font-size: 16px;
      text-align: center;
      width: 43px;
    }
    
  }
  :global(.form_item_type) {
    width: 280px;
  }
  :global(.form_item_time) {
    width: 280px;
  }
  :global(.item1) {
    width: 300px;
  }
  :global(button), :global(button:hover) {
    width: 124px;
    height: 58px;
    background: #FF954D !important;
    border-radius: 4px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 14PX;
    color: #FFFFFF;
    box-sizing: border-box;
    margin-left: 12px;
  }
}

:global(.history) {
  margin: 16px 0;
  text-align: left;
  max-width: 1440px;
  display: flex;
  :global(.title) {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 14PX;
    color: #000000;
    width: 112PX;
    flex-shrink: 0;
  }
  :global(.right) {
    :global(span) {
      display: inline-block;
      background-color: #EBEFF3;
      padding: 4PX 8PX;
      border-radius: 2PX;
      font-weight: 400;
      font-size: 14PX;
      color: #000000;
      margin-right: 20px;
      cursor: pointer;
      margin-bottom: 10PX;
    }

  }
}
:global(.tcell-capacity) {
  height: 50PX;
}
:global(.tcell-value.bold) {
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 400;
  font-size: 14PX;
  color: #000000;
  line-height: 20px;
}
:global(.tcell-value.money) {
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 400;
  font-size: 14PX;
  color: #3013FF;
  line-height: 20px;
}
:global(.tcell-value.info) {
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 400;
  font-size: 14PX;
  color: #FFA162;
  line-height: 20px;
}
:global(.btn-1) {
  padding: 5px 10px;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
}
:global(.btn-2) {
  padding: 5px 20px;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  background: #FF954D;
  border-radius: 4px;
  margin-left: 10px;
}

:global(.division) {
  position: absolute;
  width: 1440px;
  display: flex;
  justify-content: space-between;
  bottom: 15px;
  z-index: 11;
  border-top: 1px dotted #CCCCCC;
  padding: 17px 20px 0 20px;
  transform: translate(-16PX, 0);
  
  :global(.btns) {
    display: flex;
    :global(.btn1) {
      background: #FF954D;
      border-radius: 2px;
      padding: 2px 6px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 12PX;
      color: #FFFFFF;
    }
    :global(.btn2) {
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #FF954D;
      padding: 2px 6px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 12PX;
      color: #FF954D;
      margin-left: 8px;
    }
  }
  :global(.time) {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 14PX;
    color: #999999;
  }
  :global(.upload) {
    display: flex;
    :global(span) {
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 14PX;
      color: #999999;
      margin-right: 4px;
    }
  }
}
:global(.ant-table) {
  width: 1440px;
}
:global(.ant-table-content) {
  :global(table){
    border-spacing: 0 10PX;
    background-color: #F1F3F6;
  }
  :global(tr) {
    background-color: white;
  }

}
:global(.data-table) {
  display: flex;
  justify-content: center;
  background-color: #F1F3F6;
  min-height: 486px;
}