.banner {
  background-image: url('~@/assets/img/product/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 84% center;
  width: 100%;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32PX;
    color: #FFFFFF;
    text-align: center;
  }
}
.card {
  background-color: #F1F3F6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: bold;
    font-size: 32px;
    color: #000000;
    line-height: 44px;
    text-align: center;
    margin: 80px 0 60px 0;
  }
  .items {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .card_item {
    display: inline-flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px 0px rgba(0,0,0,0.1);
    border-radius: 12px;
    padding: 50px;
    &:nth-child(1) {
      margin-bottom: 30px;
    }
    div {
      &:nth-child(1) {
        margin-bottom: 100px;
      }
    }
    .text {
      .text_title {
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 500;
        font-size: 26px;
        color: #000000;
        line-height: 35px;
        text-align: left;
        margin-bottom: 16px;
      }
      .desc {
        width: 500px;
        height: 180px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 30px;
        text-align: left;
      }
    }
    img {
      background-color: aquamarine;
      cursor: pointer;
      width: 100%;
    }

  }

}