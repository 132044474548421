
.part {
    box-sizing: border-box;
    // width: 520px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #CCCCCC;
    .dotwrap {
        display: flex;
        .left {
            width: 24px;
            height: 24px;
            background: radial-gradient( 60% 118% at 0% 28%, #FFBB5E 0%, #FF866B 53%, #FF8634 100%);
            box-shadow: 0px 2px 8px 0px rgba(255,149,77,0.3);
            border-radius: 50%;
            padding: 7px;
            z-index: 99;
            div {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: white;
            }
        }
        .right {
            font-weight: 400;
            font-size: 12PX;
            color: #666666;
            line-height: 17px;
            box-sizing: border-box;
            height: 24px;
            padding: 4px 20px 4px 32px;
            background: #F1F3F6;
            border-radius: 0px 2px 2px 0px;
            margin-left: -12px;
        }
    }
    .down {
        display: flex;
        padding-left: 12px;
        .line {
           width: 1PX;
           background:
    repeating-linear-gradient(180deg,#DDDDDD 0 5PX,#0000 0 10PX)
        }
        .right {
            width: 100%;

            padding: 12px 30px 45px 30px;
            display: flex;
            :global(.bold) {
                text-align: center;
                width: 33%;
                div {
                    &:nth-child(1) {
                        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                        font-weight: bold;
                        font-size: 18px;
                        color: #000000;
                        line-height: 25px;
                    }
                    &:nth-child(2) {
                        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                        font-weight: 400;
                        font-size: 14px;
                        color: #000000;
                        line-height: 20px;
                    }
                }
                
            }
            :global(.arrow) {
                text-align: center;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                        width: 100px;
                        height: 10px;
                }
                div {
                    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
                        font-weight: 400;
                        font-size: 12px;
                        color: #666666;
                        line-height: 17px;
                        margin-top: 10px;
                }
            }
        }
    }
}